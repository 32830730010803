<template>
  <router-view />
</template>
<script>
import "../theme/main.scss";

export default {
  name: "MainView",
};
</script>
